









































































import axios from 'axios'
import {mixins} from 'vue-class-component'
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VCol, VDialog, VDivider, VIcon, VInput, VRow, VSpacer, VTextarea} from 'vuetify/lib'
import {OfflineStudent} from '~/components/course/offline-student-model'
import CourseSelect from '~/components/select/course-select.vue'
import InstructorSelect from '~/components/select/instructor-select.vue'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {createRequest, getServerBaseURL} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'


@Component({
    components: {
        CourseSelect,
        InstructorSelect,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VBtn,
        VIcon,
        VRow,
        VCol,
        VDivider,
        VTextarea,
        VSpacer,
        VInput
    }
})
export default class BulkSendMessageDialog extends mixins(DatePickerShortcutMixin) {
    static permission = AdminPermission.SendWhatsapp

    @Prop({default: false}) value: boolean

    @Prop({default: () => []}) targets: OfflineStudent[]

    @Prop() targetPayload: any[]
    @Prop() placeholderOption: string[]

    @Ref() textArea: Vue


    //  META

    //  UI DATA
    localShow: boolean = false

    //  DATA

    fileUrl: string = ''
    message: string = ''
    scheduled: Date | number | null = null

    sending = false

    get localValue() {
        return this.value
    }

    set localValue(show: boolean) {
        this.$emit('input', show)
    }

    fileUrlChanged() {
        if (this.fileUrl && !this.fileUrl.startsWith('http'))
            this.fileUrl = 'https://' + this.fileUrl
    }

    async send(test = false) {
        if (!this.message && !this.fileUrl)
            return

        if (!this.targetPayload.length)
            return

        if (test) {
            const body = {
                message: this.message,
                file_url: this.fileUrl,
                targets: this.targetPayload,
                test: true
            }
            await createRequest('/bulk-messages/with-data', 'post', {}, body).send()
            this.$message.info('Test Message Sent')
            return
        }

        this.sending = true

        const body = {
            message: this.message,
            file_url: this.fileUrl
        }
        if (typeof this.scheduled !== 'number')
            body['scheduled'] = this.scheduled ? this.scheduled.getTime() : 0

        body['targets'] = this.targetPayload.map((t) => {
            return {
                member_id: t.member_id,
                data: t.data,
                override_id: t._id || ''
            }
        })

        await createRequest('/bulk-messages/with-data', 'post', {}, body).send()

        this.localValue = false
        this.sending = false
    }

    async uploadFile() {
        const e: HTMLInputElement = this.$refs.fileinput as HTMLInputElement
        e.click()
    }

    async uploaded(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        let uploadUrl = ''
        const baseUrl = getServerBaseURL()
        if (baseUrl.startsWith('http://') || baseUrl.startsWith('https://')) {
            uploadUrl = `${baseUrl}file`
        } else {
            uploadUrl = `https://${baseUrl}file`
        }

        let form = new FormData()
        form.append('file', e.target['files'][0])

        const loading = this.$loading({
            target: '.el-dialog',
            text: 'Uploading'
        })
        const token = await this.$auth.getAccessToken()

        const res = await axios({
            method: 'post',
            url: uploadUrl,
            data: form,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        this.fileUrl = res.data['file_url']
        loading.close()
    }

    placeholderClicked(placeholder: string) {
        const textArea: HTMLTextAreaElement = this.textArea.$el.children[0] as HTMLTextAreaElement
        const bracketedPlaceholder = '{' + placeholder + '}'

        //IE support
        if ((document as any).selection) {
            const ieRange = (document as any).selection.createRange()
            ieRange.text = bracketedPlaceholder

            // Move cursor after the inserted text
            ieRange.collapse(false /* to the end */)
            ieRange.select()

            return
        }
        //MOZILLA and others
        else if (textArea.selectionStart || textArea.selectionStart === 0) {
            const startPos = textArea.selectionStart
            const endPos = textArea.selectionEnd
            textArea.value = textArea.value.substring(0, startPos)
                + bracketedPlaceholder
                + textArea.value.substring(endPos, textArea.value.length)
            textArea.selectionStart = startPos + bracketedPlaceholder.length
            textArea.selectionEnd = startPos + bracketedPlaceholder.length
            textArea.focus()
        } else {
            textArea.value += bracketedPlaceholder
        }
        this.message = textArea.value
    }

    closeDialog() {
        this.localValue = false
    }
}
