

























import {Component, Prop, Vue} from 'vue-property-decorator'
import {VBottomNavigation, VBottomSheet, VBtn, VCol, VContainer, VRow, VSheet} from 'vuetify/lib'

@Component({
    components: {
        VBottomNavigation,
        VBottomSheet,
        VSheet,
        VContainer,
        VRow,
        VCol,
        VBtn
    },
    computed: {}
})
export default class SendMessageBottomNav extends Vue {
    @Prop({default: false}) value: boolean

    @Prop({default: () => []}) selStudents: any []


    //  META DATA

    //  UI DATA

    //  DATA


    clickClear() {
        this.$emit('clickClear')
    }

    clickCopyData() {
        this.$emit('clickCopyData')
    }

    clickBulkSend() {
        this.$emit('clickBulkSend')
    }
}
