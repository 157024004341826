


























































import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VCol, VDialog, VDivider, VIcon, VRow} from 'vuetify/lib'

@Component({
    components: {
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VBtn,
        VIcon,
        VRow,
        VCol,
        VDivider
    },
    computed: {}
})
export default class LessonAttendanceDialog extends Vue {
    @Prop({default: false}) value!: boolean

    @Prop({default: ''}) title: string
    @Prop({default: () => []}) students: any[]
    @Prop({default: -1}) lessonId: number


    //  META DATA

    //  UI DATA
    localShow: boolean = false

    //  DATA

    get localValue() {
        return this.value
    }

    set localValue(show: boolean) {
        this.$emit('input', show)
    }

    get studentList() {
        const grouped = _.groupBy(this.students, item => {
            return _.map(_.map(item.attended, 'id'), 'lesson_id').includes(this.lessonId)
        })

        return {
            absent: grouped['false'] || [],
            attended: grouped['true'] || []
        }
    }

    closeDialog() {
        this.localValue = false
    }
}
