































































































































































































import PageHeader from '~/components/app/page-header.vue'
import BarChart from '~/components/chart/bar-chart.vue'
import CourseData from '~/components/course/course-data'
import {OfflineClass, OfflineClassType} from '~/components/course/offline-course-model'
import {Course} from '~/components/data-class/data-class'
import BulkSendMessageDialog from '~/components/report/bulk-send-message-dialog.vue'
import LessonAttendanceDialog from '~/components/report/lesson-attendance-dialog.vue'
import SendMessageBottomNav from '~/components/report/send-message-bottom-nav.vue'
import WtsBtn from '~/components/wts-btn.vue'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import {VBtn, VCol, VContainer, VDataFooter, VDataTable, VIcon, VPagination, VRow, VSelect, VSimpleTable, VTextField} from 'vuetify/lib'

@Component({
    components: {
        PageHeader,
        VBtn,
        VSelect,
        VTextField,
        VDataTable,
        VDataFooter,
        VContainer,
        VIcon,
        VRow,
        VCol,
        VPagination,
        VSimpleTable,
        LessonAttendanceDialog,
        WtsBtn,
        SendMessageBottomNav,
        BulkSendMessageDialog,
        BarChart
    }
})
export default class ClassIdReport extends mixins(DatePickerShortcutMixin) {
    static permission = ReportPermission.CourseReport

    //  META DATA


    //  UI DATA
    pageCount: number = 0
    tableHeaders = [
        {text: 'Username', value: 'display_name', sortable: false},
        {text: 'Student name', value: 'name', sortable: false},
        {text: 'Phone', value: 'phone', sortable: false},
        {text: 'Email', value: 'email', sortable: false},
        {text: 'Attendance', value: 'attendance'},
    ]

    tableHeadersVideo = [
        {text: 'Username', value: 'display_name', sortable: false},
        {text: 'Student name', value: 'name', sortable: false},
        {text: 'Phone', value: 'phone', sortable: false},
        {text: 'Email', value: 'email', sortable: false},
        {text: 'Progress', value: 'progress'},
    ]
    tableOption: any = {
        page: 1,
        itemsPerPage: 20
    }

    selClass: number = -1

    totalQuota: string = ''
    totalRetention: string = ''
    totalAttendance: string = ''
    totalProgress: string = ''

    search: string = ''
    selLessons: any[] = []

    showAttDialog: boolean = false
    attDialogTitle: string = ''
    attDialogLesson: number = -1


    selStudents: any[] = []
    showBottomNav: boolean = false

    showBulkSendDialog: boolean = false
    placeholderOption: string[] = ['member_id', 'display_name', 'name', 'email', 'phone', 'status']

    //  DATA
    courseId: string = ''
    classId: number = -1

    course: Course = new Course()

    attendances: any = {}
    retention: any = {}
    offlineClass: OfflineClass = new OfflineClass()
    students: any[] = []

    get isVideo() {
        return this.offlineClass.type === OfflineClassType.VIDEO
    }

    get studentRecords() {
        let stuList = this.students
        if (this.selLessons.length) {
            stuList = _.filter(stuList, item => {
                const lessonIds = _.map(_.map(item.attended, 'id'), 'lesson_id')
                return _.difference(this.selLessons, lessonIds).length < this.selLessons.length
            })
        }

        const completedLesson = this.lessonOption.length

        stuList = _.map(stuList, item => {
            const attendance = (item.attended.length || 0) / completedLesson * 100
            return _.extend({}, item, {attendance: attendance})
        })

        return stuList
    }

    get sendMessagePayload() {
        if (this.selStudents.length) {
            return _.map(this.selStudents, item => {
                return {
                    member_id: item.member_id,
                    data: Object.assign({}, item)
                }
            })
        }
        return []
    }

    get lessonOption() {
        return _.filter(this.attendances.lessons, item => {
            return Date.now() > item.timestamp
        })
    }

    get donutConfig() {
        return {
            chart: {
                type: 'donut',
                height: '100%'
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => ''
                    },
                    formatter: (value) => value.toFixed(0)
                }
            }
        }
    }

    get quotaConfig() {
        return {
            ...this.donutConfig,
            labels: ['Reserved', 'Applied', 'Paid', 'Remaining']
        }
    }

    get quotaData() {
        const reserved = this.offlineClass.preserved || 0
        const applied = this.offlineClass.applied || 0
        const paid = this.offlineClass.paid || 0
        const remain = this.offlineClass.quota - reserved - applied - paid

        this.totalQuota = `${reserved + applied + paid}/${this.offlineClass.quota}`
        return [reserved, applied, paid, remain]
    }

    get retentionConfig() {
        return {
            ...this.donutConfig,
            labels: ['Retention', 'Attrition']
        }
    }

    get retentionData() {
        if (_.isEqual(this.retention, {})) {
            return []
        }
        const stay = this.retention.stay || 0
        const remain = this.retention.total - stay

        this.totalRetention = (stay / this.retention.total * 100).toFixed(2) + '%'

        return [stay, remain]
    }

    get attendanceConfig() {
        const label = _.map(this.attendances.lessons, item => {
            return item.lesson_id + 1
        })
        return {
            chart: {
                height: 180,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                curve: 'straight',
            },
            xaxis: {
                categories: label,
            },
            yaxis: {
                max: 100
            },
            tooltip: {
                shared: true,
                followCursor: true,
            }
        }
    }

    get attendanceData() {
        const percentage = _.isNil(this.attendances.class) ? 0 : this.attendances.class.percentage
        this.totalAttendance = ((percentage || 0) * 100).toFixed(2) + '%'
        return [{
            name: 'Student',
            data: _.map(this.attendances.lessons || [], item => _.round(item.percentage * 100, 1))
        }]
    }

    get progressConfig() {
        return {
            chart: {
                type: 'bar',
                height: 180
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: ['0%', '> 0 - < 50%', '>= 50% - < 80%', '>= 80% - < 100%', '100%']
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => '',
                    },
                    formatter: (value) => value
                },
            }
        }
    }

    get progressData() {
        if (!this.students.length) {
            this.totalProgress = '0'
            return [{name: 'Student', data: []}]
        }
        const progress = _.map(this.students, 'progress')

        this.totalProgress = (_.sum(progress) / progress.length * 100).toFixed(2)

        const grouped = _.groupBy(progress, item => {
            if (item === 1)
                return 4
            else if (item >= 0.8)
                return 3
            else if (item >= 0.5)
                return 2
            else if (item === 0)
                return 0
            else
                return 1
        })

        const patched = Object.assign({0: [], 1: [], 2: [], 3: [], 4: []}, grouped)
        const counted = _.map(_.values(patched), item => item.length)
        return [{name: 'Student', data: counted}]
    }


    async created() {
        this.courseId = this.$route.params.course_id
        this.classId = parseInt(this.$route.params.class_id)

        this.course = await CourseData.shouldGetCourse(this.courseId)

        this.selClass = this.classId

        await this.fetchClassDashboard()
    }

    async fetchClassDashboard() {
        const res = await createRequest(`/dashboard/course/${this.courseId}/class/${this.classId}`, 'get').send()

        this.attendances = res.data.attendances
        this.retention = res.data.retention
        this.offlineClass = res.data.class
        this.students = res.data.students
    }

    formatClassOption(offlineClass) {
        return `[${offlineClass.id}] ${offlineClass.title}`
    }

    formatLessonOption(lesson) {
        return `Lesson ${lesson.lesson_id + 1}`
    }

    @Watch('selClass')
    watchSelClass(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.$router.push(`/report/course/${this.courseId}/class/${newVal}`)
        }
    }

    clickViewLesson(lessonId) {
        const lesson = this.offlineClass.lessons[lessonId]
        this.attDialogTitle = moment(lesson.start).format('DD/MM/YYYY') + ' Lesson' + (lessonId + 1)
        this.attDialogLesson = lessonId
        this.showAttDialog = true
    }

    clickDownloadLesson(lessonId) {

    }

    @Watch('selStudents')
    watchSelStudents(newVal, oldVal) {
        this.showBottomNav = newVal.length > 0
    }

    clickClear() {
        this.selStudents = []
    }

    clickCopyData() {
        console.log(this.selStudents)
        const str = this.selStudents.map(stu => {
            return [stu.member_id, stu.display_name, stu.name, stu.phone, stu.email].join('\t')
        }).join('\n')

        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })

        this.$message.success('Copied')
    }

    clickBulkSend() {
        this.showBulkSendDialog = true
    }
}
